// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/banner2.webp";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="25vh"
        width="100%"
        zIndex={1000}
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "80%",
          display: "grid",
          placeItems: "center",
        }}   
      >
      <MKBox
        minHeight="25vh"
        width="100%"
        position="absolute"
        left={0}
        top={2}
        zIndex={-1}
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "80%",
          display: "grid",
          placeItems: "center",
          filter: "blur(12px)",
        }}   
      ></MKBox>
      </MKBox>
      <MKBox pl={{ xs: 5, md: 10, xl:10 }}>
      <MKBox pt={{ xs: 8, sm: 12, lg:16 }} px={{ xs: 0, sm: 2, lg:12 }} pr={{ xs: 3, sm: 6, lg:9 }}>  
      <MKTypography variant="h1" my={2}>Privacy Policy</MKTypography>
      <MKTypography variant="body2" color="text" pb={4}>This Privacy Policy describes how Jules Morel collects, uses, and shares information about you when you use our VR LiDAR Viewer and Kalari mobile applications (collectively, the "Apps").</MKTypography>
      <MKTypography variant="h3" my={2}>Information We Collect</MKTypography>
      <MKTypography variant="body2" color="text"pb={4}>We do not collect any personal information from you when you use our Apps.</MKTypography>
        <MKTypography variant="h3" my={2}>How We Use Information</MKTypography>
        <MKTypography variant="body2" color="text" pb={4}>Since we do not collect any personal information, we do not use any information about you.</MKTypography>
        <MKTypography variant="h3" my={2}>How We Share Information</MKTypography>
        <MKTypography variant="body2" color="text" pb={4}>Since we do not collect any personal information, we do not share any information about you.</MKTypography>
        <MKTypography variant="h3" my={2}>Third-Party Services</MKTypography>
        <MKTypography variant="body2" color="text" pb={4}>Our Apps do not use any third-party services.</MKTypography>
        <MKTypography variant="h3" my={2}>Changes to this Policy</MKTypography>
        <MKTypography variant="body2" color="text" pb={4}>We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify you by email (if we have your email address) or by posting a notice in our Apps prior to the effective date of the changes. We encourage you to periodically review this page for the latest information on our privacy practices.</MKTypography>
        <MKTypography variant="h3" my={2}>Contact Us</MKTypography>
        <MKTypography variant="body2" color="text" pb={12}>If you have any questions or concerns about our Privacy Policy or our data practices, please contact us at julesmorelinc (at) gmail (dot) com.</MKTypography>
      </MKBox>  
      </MKBox>
      <MKBox pt={0} px={1} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;