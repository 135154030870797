// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

//data
import papiers from "papiers";
import papiers2 from "papiers2";
import imgcloud from "assets/images/wordcloud.webp"

// Images
import bgImage from "assets/images/banner2.webp";

function Publications() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "80%",
          display: "grid",
          placeItems: "center",
        }}     
      >
        <Container>        
            <MKTypography
              variant="h1"
              color="white"
              mt={6}
              mb={1}
              textAlign="right"
              px={2}
            >
              Publications
            </MKTypography>         
        </Container>
      </MKBox>

      <MKBox pt={{ xs: 4, sm: 4, lg:4 }} px={{ xs: 2, sm: 4, lg:18 }}> 
      
      <MKTypography variant="h2" textAlign="center" mt={2}>
        Refereed Articles
      </MKTypography>
      {papiers.map((entry, index) => (
        <MKBox key={index} mt={4}>
        <MKBox width="100%" py={4}>
            <Grid container>
              <Grid item xs={12} md={4} xl={4} sx={{ px: 4 }}>
              <MKBox position="relative" borderRadius="lg">
                <MKBox
                  component="img"
                  src={entry.image}
                  alt={entry.title}
                  borderRadius="lg"
                  width="100%"
                  position="relative"
                  zIndex={1}
                />
                <MKBox
                  borderRadius="lg"
                  shadow="xxl"
                  width="100%"
                  height="100%"
                  position="absolute"
                  left={0}
                  top={0}
                  sx={{
                    backgroundImage: `url(${entry.image})`,
                    transform: "scale(0.96)",
                    filter: "blur(12px)",
                    backgroundSize: "cover",
                  }}
                />
              </MKBox>
              </Grid>
              <Grid item xs={12} md={8} xl={8} sx={{ px: { xs: 4, md: 4 }}}>
              <MKBox>
              <MKTypography variant="h5" color="text" sx={{ mt: { xs: 1, md: 0 }}} >
              {entry.title}

              <MKButton
                component={MuiLink}
                href={entry.link}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ mx: 1 }}
              >
                <Icon sx={{ fontWeight: "bold", mr: 1 }}>picture_as_pdf</Icon> 
                PDF
              </MKButton> 
              </MKTypography>
              <MKTypography variant="subtitle1" color="text" mt={1}>
                {entry.journal}
              </MKTypography>         
              <MKTypography variant="body2" color="text" mt={1} mb={2}>
                {entry.authors} ({entry.date})      
              </MKTypography>
              <MKTypography variant="body2" color="text" align="justify">
                {entry.description}
              </MKTypography>
              </MKBox>  
              </Grid>
            </Grid> 
        </MKBox>       
        </MKBox>  
      ))}
      <MKTypography variant="h2" textAlign="center" mt={6} mb={4}>
        Thesis
      </MKTypography>
      <MKBox width="100%" py={4}>
            <Grid container>
              <Grid item xs={12} md={4} xl={4} sx={{ px: 4 }}>
              <MKBox position="relative" borderRadius="lg">
                <MKBox
                  component="img"
                  src={imgcloud}
                  alt="thesis"
                  borderRadius="lg"
                  width="100%"
                  position="relative"
                  zIndex={1}
                />
                <MKBox
                  borderRadius="lg"
                  shadow="xxl"
                  width="100%"
                  height="100%"
                  position="absolute"
                  left={0}
                  top={0}
                  sx={{
                    backgroundImage: `url(${imgcloud})`,
                    transform: "scale(0.96)",
                    filter: "blur(12px)",
                    backgroundSize: "cover",
                  }}
                />
              </MKBox>
              </Grid>
              <Grid item xs={12} md={8} xl={8} sx={{ px: { xs: 4, md: 4 }}}>
              <MKBox>
              <MKTypography variant="h5" color="text" sx={{ mt: { xs: 1, md: 0 }}} >
                Surface Reconstruction Based on Forest Terrestrial LiDAR Data

              <MKButton
                component={MuiLink}
                href="http://www.theses.fr/2017AIXM0039"
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ mx: 1 }}
              >
                <Icon sx={{ fontWeight: "bold", mr: 1 }}>picture_as_pdf</Icon> 
                Link
              </MKButton> 
              </MKTypography>
              <MKTypography variant="subtitle1" color="text" mt={1} mb={3}>
                February 2017
              </MKTypography>         
              <MKTypography variant="body2" color="text" mb={2}>
              French Institute of Pondicherry, UMIFRE 21 CNRS-MAE, Pondicherry, India. 
              </MKTypography>
              <MKTypography variant="body2" color="text" textAlign="left">
              Laboratoire des Sciences de l’Information et des Systèmes, UMR 7296, Aix Marseille, France.
              </MKTypography>
              </MKBox>  
              </Grid>
            </Grid> 
        </MKBox>
      <MKTypography variant="h2" textAlign="center" mt={6}>
        Other Publications
      </MKTypography>
      <MKBox p={4} mb={8}>
      {papiers2.map((entry, index) => (
        <MKBox key={index} mt={4}>
        <MKTypography variant="h5" color="text" sx={{ mt: { xs: 1, md: 0 }}} >
            {entry.title}
        </MKTypography>    
        <MKTypography variant="subtitle1" color="text">
          {entry.journal}
        </MKTypography>         
        <MKTypography variant="body2" color="text">
          {entry.authors} ({entry.date})      
        </MKTypography>
        </MKBox>  
      ))}
      </MKBox>
      </MKBox>  
      <MKBox pt={0} px={1} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Publications;