/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

// Material Kit 2 PRO React examples
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import Masonry from '@mui/lab/Masonry';

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

//data
import data from "data"

// Images
import bgImage from "assets/images/banner2.webp";
import maltImage from "assets/images/logo_malt.webp";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="55vh"
        width="100%"
        zIndex={1000}
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "80%",
          display: "grid",
          placeItems: "center",
        }}   
      >
      <MKBox
        minHeight="55vh"
        width="100%"
        position="absolute"
        left={0}
        top={2}
        zIndex={-1}
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "80%",
          display: "grid",
          placeItems: "center",
          filter: "blur(12px)",
        }}   
      ></MKBox>
        <Container>        
            <MKTypography
              variant="h2"
              color="white"
              mt={6}
              mb={1}
              textAlign="right"
              px={2}
            >
              Jules Morel
            </MKTypography>
            <MKTypography variant="body2" color="white" textAlign="right" px={2} mt={2}>
              Computer Graphics Enthusiast
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="right" px={2} mt={1}>
              From Researcher 
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="right" px={2} mt={0}>
              To Freelance Developer
            </MKTypography>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              pr={2}
              mt={3}
            >
            <Tooltip title="Github" placement="top">
            <MKTypography
              component={MuiLink}
              target="_blank"
              href="https://github.com/julesmorel#"
              variant="button"
              p={1}
              textAlign="right"            
            >
              <MKBox component="i" color="white" className="fab fa-github fa-lg"/>
            </MKTypography>
            </Tooltip>
            <Tooltip title="LinkedIn" placement="top">
            <MKTypography
              component={MuiLink}
              href="https://linkedin.com/in/jules-morel-73975a39"
              target="_blank"
              variant="button"
              textAlign="right"
              p={1}
            >
              <MKBox component="i" color="white" className="fab fa-linkedin fa-lg" />
            </MKTypography>
            </Tooltip>
            <Tooltip title="Unsplash" placement="top">
            <MKTypography
              component={MuiLink}
              target="_blank"
              href="https://unsplash.com/@julios_morelos"
              variant="button"
              textAlign="right"
              p={1}
            >
              <MKBox component="i" color="white" className="fab fa-unsplash fa-lg" />
            </MKTypography>
            </Tooltip>
            </Grid> 
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              pr={3}
            >           
            <Tooltip title="Contactez-moi sur Malt" placement="bottom">
            <Link 
            href="https://www.malt.fr/profile/julesmorel"
            target="_blank"
            onClick={() => {
              window.dataLayer.push({
                event: 'maltview'
              });
            }}
            >
            <MKBox
              component="img"
              src={maltImage}
              alt={"logo Malt"}
              borderRadius="lg"
              width="83px"
              position="relative"
              zIndex={1}
            />
            </Link>
            </Tooltip>
            </Grid> 
        </Container>
      </MKBox>
      <MKBox pl={{ xs: 5, md: 10, xl:10 }}>
      <MKBox pt={{ xs: 8, sm: 12, lg:16 }} px={{ xs: 0, sm: 2, lg:8, xxl:20, xxxl:50 }} pr={{ xs: 3, sm: 4, lg:9 }}>  
      <Masonry columns={{ xs: 1, md: 2, xl:3 }} spacing={{ xs: 2, md: 10, xl:12 }}>
      {data.map((d, index) => (       
          <MKBox key={index} pt={1} px={0} pb={{ xs: 6, md: 2, xl:2 }}>
          <RaisedBlogCard             
            image={d.image}
            title={d.title}
            category={d.category}
            descriptions={d.texts}
            actions={d.actions}
            date={d.date}
          />
          </MKBox>  
        ))}
      </Masonry>
      </MKBox>  
      </MKBox>
      <MKBox pt={0} px={1} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
