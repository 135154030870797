import img1 from "assets/images/desktop2.webp";
import img2 from "assets/images/dtm.webp";
import img3 from "assets/images/mnt.webp";
//import img4 from "assets/images/screenshot_air_quality.webp";
import img5 from "assets/images/screenshot_edl.webp";
import img6 from "assets/images/seg.webp";
import img7 from "assets/images/computree.webp";
import img8 from "assets/images/lvv.webp";
import img9 from "assets/images/pdalmnt.webp";
import img10 from "assets/images/urumi3.webp";
import img11 from "assets/images/potree.webp";
import img12 from "assets/images/renderforest.webp";
import img13 from "assets/images/segforest.webp";
import img14 from "assets/images/ganges.webp";
import img15 from "assets/images/csrbf_blender.webp";
import img16 from "assets/images/recon.webp";
import img17 from "assets/images/jahaz.webp";
import img18 from "assets/images/pointblender.webp";
import img19 from "assets/images/aas22.webp";
import img20 from "assets/images/mirukuruonline.webp";

import './global.js';

const data = [
            {
            title: "Streamlining LiDAR Data: Unveiling Our Online Storage Solution",
            date:"December 19, 2023",
            category:{ color: "secondary", label: "Online Storage Application" },
            image:img20,
            texts: [
                {
                    text:"In the dynamic landscape of remote sensing, I am excited to introduce my latest creation for the company Mirukuru: a full-stack online storage application tailored for LiDAR files. With effortless upload, fancy visualization, easy sharing, and secure download options, this application offers a seamless and efficient way for managing substantial remote sensing data files.",
                },
                {
                    text:"Powered by the Potree point cloud viewer, the versatile Strapi framework, and the scalability of Amazon Web Services, my application redefines the experience of LiDAR data storage and accessibility.",
                },
                {
                    text:"If you're interested in exploring our platform, please feel free to contact us to inquire about account creation and discover the possibilities firsthand.",
                },
            ],
            height: 200,
            actions:[
                {
                    type: "external",
                    route: "https://mirukuru-online.com",
                    color: "info",
                    label: "mirukuru-online",
                    icon: "public",
                },
            ],
            },
    {
        title: "Importing Geometric Primitives using Blender's Python API ",
        date:"October 27, 2023",
        category:{ color: "secondary", label: "Blender" },
        image:img19,
        texts: [
            {
                text:"In the realm of forest modeling, where cylindrical models are pivotal, Blender emerges as the ultimate tool to produce captivating images and animations."
            },
            {
                text:"If you've ever wanted to import cylindrical building blocks into Blender quickly in order to make good looking renders, I have good news for you. I've developed a Python script that allows you to load cylinder parameters from a CSV file, making the creation of trees cylinder models in Blender a breeze."
            },
            {
                text:"Have a look at the script and the full rendered animation by following the links below."
            },
        ],
        height: 30,
        actions:[
            {
                type: "external",
                route: "https://github.com/julesmorel/cylinder_import_blender_script",
                color: "dark",
                label: "Github",
                icon: "save",
            },
            {
                type: "external",
                route: "https://www.youtube.com/watch?v=onyc6UCZvjg",
                color: "error",
                label: "Youtube",
                icon: "video_library",
            },
            ],
        },
    {
        title: "Integrating Colorful Point Clouds into Blender 3.1 and Beyond",
        date:"June 13, 2023",
        category:{ color: "secondary", label: "Blender" },
        image:img18,
        texts: [
            {
                text:"For users of Blender 3.1 and newer versions, a highly effective drop-in replacement script for the stock Blender PLY import module has been developed. This isn't just a plug-in—it's a complete overhaul that improves the importing process for PLY files, especially those containing both point location data and color information. The script simplifies the integration of colored point cloud data into Blender, allowing you to create more realistic and detailed 3D models. This replacement script blends seamlessly into your Blender workflow and holds the potential to significantly enhance your 3D point cloud rendering capabilities."
            },
            {
                text: "Check the repo below for the script and its installation guide."
            }
        ],
        height: 30,
        actions:[
            {
                type: "external",
                route: "https://github.com/TombstoneTumbleweedArt/import-ply-as-verts",
                color: "dark",
                label: "Github",
                icon: "save",
            },
            ],
        },
    {
        title: "How to Convert ContextCapture 3mx Files Into Open Source Formats",
        date:"December 19, 2022",
        category:{ color: "secondary", label: "Open Source" },
        image:img17,
        texts: [
            {
                text:"If you're working with 3D models generated by Bentley ContextCapture, you may have encountered the 3mx/3mxb format. This format stores efficiently high-resolution models in an octree LOD structure but remains proprietary making it difficult to work with in open-source software.",
            },
            {
                text:"Fortunately, I've updated a plugin that allows you to import 3mx files into the latest version of OpenSceneGraph. This makes it possible to convert them into open-source formats that are more accessible and widely compatible.",
            },
            {
                text:"If you're interested in learning more, check out my updated plugin below."
            }
        ],
        height: 30,
        actions:[
            {
                type: "external",
                route: "https://github.com/julesmorel/osgPlugins-3mx",
                color: "dark",
                label: "Github",
                icon: "save",
            }
        ],
    },
    {
        title: "3D Mesh Reconstruction Of Complete Forest Plots",
        date:"September 21, 2022",
        category:{ color: "secondary", label: "Open Source" },
        image:img16,
        texts: [
            {
                text:"While processing LiDAR scans of forest plots, isolating individual trees and reconstructing them into 3D models is still a major challenge. That's where our deep learning model comes in. By leveraging the power of machine learning, we're able to segment multi-view LiDAR scans with ease, and by relying on region growing algorithm we are able to isolate individual trees for further processing. The region growing algorithm merges points that are close enough in terms of the smoothness constraint, resulting in clusters of points that represent individual trees.",
            },
            {
                text:"Next, we use an appropriately parameterized version of aRchi, a powerful R package that provides a set of tools to manipulate, visualize, and compute metrics from quantitative structural models of trees (i.e., the so-called 'QSM'). With aRchi, we're able to generate detailed 3D mesh reconstructions of individual trees that accurately represent their structure and morphology.",
            },
        ],
        height: 30,
        actions:[
            {
                type: "external",
                route: "https://github.com/julesmorel/forest_model",
                color: "dark",
                label: "Github",
                icon: "save",
            },
            {
                type: "external",
                route: "https://github.com/umr-amap/aRchi",
                color: "info",
                label: "aRchi",
                icon: "public",
            },
        ],
    },
    {
    title: "Deep Learning segmentation: Scaling Up Our Method To Complete Forest Plots",
    date:"August 28, 2022",
    category:{ color: "secondary", label: "Open Source" },
    image:img13,
    texts: [
        {
            text:"Processing large-scale forest plots with millions of points can be a daunting task, but we have developed a package that makes it easier. By combining new implementations, code optimization, and PDAL plugins pipeline, our package is now able to accurately segment multiple scans of a forest plot, each containing more than 20 million points." 
        },
        {
            text:"Our segmentation method relies on deep learning to accurately identify the wood from the scans. Once we have the set of points segmented as wood, we use a region growing algorithm to identify clusters of points that represent individual trees. This algorithm merges points that are close enough in terms of smoothness, resulting in highly accurate 3D models of individual trees."
        },
    ],
    height: 30,
    actions:[
        {
            type: "external",
            route: "https://github.com/julesmorel/PointNet2",
            color: "dark",
            label: "Github",
            icon: "save",
        },
        {
            type: "external",
            route: `${global.deep}`,
            color: "secondary",
            label: "PDF",
            icon: "picture_as_pdf",
        },
    ],
    },
    {
    title: "Digital Terrain Reconstruction Using PDAL: A New Alternative",
    date:"July 20, 2022",
    category:{ color: "secondary", label: "Open Source" },
    image:img9,
    texts: [
        {
            text:"As a researcher in computer science, I'm always looking for ways to improve existing methods and techniques. That's why I decided to explore an alternative to my own terrain reconstruction method by composing a terrain reconstruction operation as a pipeline of Point Data Abstraction Library (PDAL) plugins.",
        },
        {
            text:"The operation reads and crops multiple scans of a forest plot, then identifies the ground points and reconstructs the terrain surface as a Poisson surface. By using PDAL, we're able to process large datasets quickly and efficiently, resulting in highly accurate digital terrain models.",
        },
        {
            text:"This approach represents a significant improvement, as it allows for greater scalability. With PDAL, we're able to process multiple scans of a forest plot with ease."
        },
    ],
    height: 30,
    actions:[
        {
            type: "external",
            route: "https://github.com/julesmorel/pdal_scripts",
            color: "dark",
            label: "Github",
            icon: "save",
        },
    ],
    },
    {
    title: "Open Sourcing Our Digital Terrain Model Algorithm",
    date:"April 26, 2022",
    category:{ color: "secondary", label: "Open Source" },
    image:img2,
    texts: [
        {
            text:"As a researcher and developer, I am passionate about open-source software and its potential to advance the field of computer science. That's why I am excited to announce that my digital terrain model algorithm, published in 2020, is now available as an open-source Python package. ",
        },
        {
            text: "This algorithm uses deep learning to accurately separate vegetation from potential ground points, filling holes by blending multiple local approximations through the partition of unity principle. It then improves accuracy by pushing the surface towards the data points through an iterative convection model.",
        },
    ],
    height: 30,
    actions:[
        {
            type: "external",
            route: "https://github.com/julesmorel/dtm_pybind11",
            color: "dark",
            label: "Github",
            icon: "save",
        },
        {
            type: "external",
            route: "https://rdcu.be/cS3lo",
            color: "secondary",
            label: "PDF",
            icon: "picture_as_pdf",
        },
    ],
    },
    {
    title: "Now Freelancing!",
    date:"April 28, 2021",
    category:{ color: "secondary", label: "Activity" },
    image:img1,
    texts: [
        {
            text:"After 15 years of experience in the field of scientific computing, including 10 years of research in geometric modeling, I am excited to announce that I am now available for freelance work.",
        },
        {
            text:"As a skilled developer with expertise in geometric modeling, I offer services to process, filter, classify, and reconstruct your 3D point clouds. I am also available to take part in innovative scientific computing projects, bringing my expertise to the design, architecture, and development of scientific software.",
        },
        {
            text:"If you're looking for a dedicated and experienced developer to bring your project to the next level, don't hesitate to get in touch. You can reach me on malt.fr to discuss your project's needs and my availability. I'm looking forward to hearing from you and discussing how we can work together to achieve your goals.",
        },
    ],
    height: 200,
    actions:[
        {
            type: "external",
            route: "https://www.malt.fr/profile/julesmorel",
            color: "info",
            label: "malt",
            icon: "public",
        },
    ],
    },
    {
    title: "Point Cloud Studio",
    date:"December 3, 2020",
    category:{ color: "secondary", label: "Online Point Cloud Viewer" },
    image:img11,
    texts: [
        {
            text:"Introducing Point Cloud Studio - an online platform for hosting, visualizing, analyzing, and sharing 3D point clouds. Developed using Potree, AWS, and ReactJS, Point Cloud Studio offers a seamless experience to explore the nuances of 3D point clouds."
        },
        {
            text:"With Point Cloud Studio, you can upload your own 3D point clouds, view them from various angles, perform analyses and extract information, and share them with others.",
        },
        {
            text:"Although registration of new users is currently closed, the frontpage serves as a proof of concept of the platform's capabilities.",
        },
    ],
    height: 30,
    actions:[
        {
            type: "external",
            route: "https://pointcloud.studio/",
            color: "info",
            label: "pointcloud.studio",
            icon: "public",
        },
    ],
    },
    {
    title: "Generating Terrain Surfaces from Drone Data using Photogrammetry",
    date:"June 7, 2020",
    category:{ color: "info", label: "Scientific Paper" },
    image:img3,
    texts: [
        {
            text: "By adapting our digital terrain model (DTM) extraction algorithm designed for terrestrial LiDAR point clouds, we have now developed a pipeline to process point clouds generated from drone imagery through photogrammetry software. Our algorithm accurately identifies and extracts ground points, and then models the ground surface as an implicit surface enabling to generate high-resolution terrain surfaces. The resulting 3D model can be used for a wide range of applications, including urban planning, land surveying, and environmental monitoring. Contact me for more information on how we can help you leverage the power of drone data."
        },
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: "https://graphics.c.u-tokyo.ac.jp/archives/stag2020jules.pdf",
            color: "secondary",
            label: "PDF",
            icon: "picture_as_pdf",
        },
    ],
    },
    // {
    // title: "Pocket Air Quality",
    // date:"February 7, 2019",
    // category:{ color: "success", label: "Android Application" },
    // image:img4,
    // texts: [
    //     {
    //         text: "Are you concerned about the air quality in your area? Look no further than Pocket Air Quality, a new Android app that collects air quality information online and presents different pollutant concentrations and the overall air cleanliness at the closest station."
    //     },
    //     {
    //         text: "With Pocket Air Quality, you can easily monitor the air quality in your area in real-time. The app provides up-to-date information on various pollutants, including PM2.5, PM10, ozone, nitrogen dioxide, and sulfur dioxide. It also shows the overall air cleanliness based on the air quality index (AQI) for your location."
    //     },
    //     {
    //         text: "The app is easy to use and provides a simple interface to navigate. It allows you to view the air quality information for your current location or search for air quality data for any other location around the world."
    //     },
    // ],
    // height: 80,
    // actions:[
    //     {
    //         type: "external",
    //         route: "https://play.google.com/store/apps/details?id=com.julesmorel.airquality",
    //         color: "success",
    //         label: "Playstore",
    //         icon: "shop",
    //     },
    //   ],
    // },
    {
    title: "Modeling Trees  With The Grove",
    date:"January 7, 2019",
    category:{ color: "secondary", label: "Blender" },
    image:img12,
    texts: [
        {
            text:" Have you ever wondered how to create realistic 3D models of trees for your projects? Look no further than The Grove, a powerful 3D tree growing software packaged as a Blender addon."
        },
        {
            text: "As a researcher in computer science specialized in 3D modeling, I have found The Grove to be an indispensable tool for simulating 3D models of natural trees of several different species. In combination with a LiDAR simulator, I was able to produce labeled point clouds that I used to train my deep learning models."
        },
        {
            text: "With The Grove, you can easily create and customize trees of different species, growth stages, and environments. The software provides a wide range of features, including realistic branch growth, foliage, and bark textures. Its integration with Blender makes it easy to export your models in various formats to use in your projects."
        }
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: "https://www.thegrove3d.com/",
            color: "info",
            label: "The Grove",
            icon: "public",
        },
        {
            type: "external",
            route: "https://youtu.be/Gv-s3A0HmhM",
            color: "error",
            label: "Youtube",
            icon: "video_library",
        },
        ],
    },
    {
    title: "Non Photo-Realistic Rendering With OpenGL ES",
    date:"July 24, 2019",
    category:{ color: "secondary", label: "OpenGL" },
    image:img5,
    texts: [
        {
            text: "Inspired by the Eye Dome Lightning (EDL) shading technique and Screen space ambient occlusion (SSAO), I implemented in LiDAR VR Viewer a new shader for OpenGL ES designed to enhance the rendering of point clouds. ",
        },
        {
            text:"As the shading function rely only on the projected depth, and because the variable gl_FragDepth is missing in OpenGL ES, a workaround was to implement a custom depth buffer using a frameBuffer object."
        },
        {
            text:"In practice, the multi-pass rendering is done in 2 steps: First we create a new frameBuffer and render the scene as usual with this new frameBuffer bound as the active one. Then we bind to the default frameBuffer, then draw a quad that spans the entire screen with the new frameBuffer’s color buffer as its texture."   
        },
        {
            text:"Vertex and Fragment shaders can be dowloaded by clicking on the button bellow."   
        },
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: `${global.shaders}`,
            color: "dark",
            label: "Shaders",
            icon: "text_snippet",
        },
        {
            type: "external",
            route: "https://play.google.com/store/apps/details?id=com.ifp.liag.lidarviewer",
            color: "success",
            label: "Playstore",
            icon: "shop",
        },
    ],
    },
    {
    title: "Segmentation Of Point Clouds Using Deep Learning",
    date:"June 9, 2019",
    category:{ color: "info", label: "Scientific Paper" },
    image:img6,
    texts: [
        {
            text: "I have developed a novel approach for accurately segmenting terrestrial LiDAR point clouds of trees into leaf and wood points. My method is based on deep learning and a class decision process, which effectively learns the characteristic point patterns of trees. By separating leaf and wood points, we can better analyze the structure and health of trees, and enable more efficient processing of point cloud data. This method has shown promising results in various experiments, and we hope it will contribute to the development of more accurate and efficient techniques for point cloud segmentation."
        },
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: `${global.deep}`,
            color: "secondary",
            label: "PDF",
            icon: "picture_as_pdf",
        },
        {
            type: "external",
            route: "https://youtu.be/sZ8PB1XHyeo",
            color: "error",
            label: "Youtube",
            icon: "video_library",
        },
        ],
    },
    {
    title: "Surface Reconstruction Of Incomplete Datasets",
    date:"August 10, 2018",
    category:{ color: "info", label: "Scientific Paper" },
    image:img15,
    texts: [
        {
            text: "Due to the occlusion phenomenom occuring while acquiring LiDAR scans in forests, the objects of the forests ecosystems are often only partially sampled. Starting out from this observation, I designed a novel surface reconstruction method, which focuses on offering complete and closed mesh models. To accomplish this task, this approach builds upon a known a priori model that coarsely describes the scanned object to guide the modeling of the shape.",
        },
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: `${global.csrbf}`,
            color: "secondary",
            label: "PDF",
            icon: "picture_as_pdf",
        },
        ],
    },
    {
    title: "Open Source Tree Modeling: Sharing My PhD Work",
    date:"September 25, 2017",
    category:{ color: "secondary", label: "Open source" },
    image:img7,
    texts: [
        {
            text: "As part of my PhD research in the field of forestry, I have developed innovative methods for modeling trees using point cloud data. Now, I'm excited to announce that a part of my work has been made available in the latest release of the open source platform Computree.",
        },
        {
            text:"With this release, I'm hoping to share my research with the wider community of researchers and practitioners in the field of forestry and tree modeling. This release includes detailed documentation of the computational steps involved in the tree modeling process, as well as the code required to implement these methods."
        }
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: "https://computree.onf.fr",
            color: "info",
            label: "Computree",
            icon: "public",
        },
        ],
    },
    {
    title: "LiDAR VR Viewer: Exploring 3D Point Clouds in Virtual Reality",
    date:"July 6, 2015",
    category:{ color: "success", label: "Android Application" },
    image:img8,
    texts: [
        {
            text: "LiDAR VR Viewer is an Android application that provides an immersive virtual reality experience with 3D point clouds. Available for free on the Google Play Store, the app allows users to navigate through point cloud data using simple head-mounted displays like Google Cardboard, along with a Bluetooth controller for easy and intuitive interaction."
        },
        {
            text: "With over 50K downloads, LiDAR VR Viewer provides an affordable and accessible way for users to explore and visualize LiDAR data in a unique and engaging way. Whether for educational or entertainment purposes, the app offers a novel approach to interacting with 3D point clouds and brings the power of LiDAR technology to a wider audience.",
        },
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: "https://play.google.com/store/apps/details?id=com.ifp.liag.lidarviewer",
            color: "success",
            label: "Playstore",
            icon: "shop",
        },
        ],
    },
    {
    title: "Kalari: A Comprehensive App for Kalarippayattu Practitioners",
    date:"March 10, 2017",
    category:{ color: "success", label: "Android Application" },
    image:img10,
    texts: [
        {
            text: "Kalarippayattu, an ancient Indian martial art form, requires intense practice and discipline. To help practitioners perfect their postures, exercises, and sequences, the members of Kshetra Kalari in Auroville, India, developed Kalari, a comprehensive mobile application. With Kalari, practitioners can also learn about the different attacks and defense moves done with weapons. The app has been downloaded over 10K times and has received rave reviews."
        },
        {
            text: "Moreover, Kalari has been used as a foundation for the writing of the first Kalarippayattu guidebook. Self-edited and available on Amazon Kindle, this guidebook provides an in-depth understanding of the practice and serves as a valuable resource for both beginners and advanced practitioners."
        },
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: "https://play.google.com/store/apps/details?id=com.julesmorel.kalari",
            color: "success",
            label: "Playstore",
            icon: "shop",
        },
        {
            type: "external",
            route: "https://www.amazon.com/Kalarippayattu-Guide-Book-Jules-Morel-ebook/dp/B08TZV6WQL",
            color: "info",
            label: "Ebook",
            icon: "auto_stories",
        },
        ],
    },
    {
    title: "3D Point Clouds Generation With Structure From Motion",
    date:"March 11, 2016",
    category:{ color: "secondary", label: "Structure from motion" },
    image:img14,
    texts: [
        {
            text: "With the help of Structure from Motion (SfM) algorithms and photogrammetry software, I generated high-quality 3D point clouds of several archeological sites in South India. By capturing multiple images of the sites from different angles, the SfM algorithms were able to reconstruct the 3D geometry of the structures and generate accurate point clouds. These point clouds were then processed using mesh reconstruction techniques to create detailed 3D mesh models. You can check out some of these models on my Sketchfab profile."
        },
    ],
    height: 80,
    actions:[
        {
            type: "external",
            route: "https://sketchfab.com/3d-models/descent-of-the-ganges-mahabalipuram-0b67258142634592949281094ca48b38",
            color: "info",
            label: "Sketchfab",
            icon: "public",
        },
        ],
    },
];

export default data;