import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <h1>&nbsp; Page not found</h1>
      <p>&nbsp;  &nbsp; &nbsp;Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default PageNotFound;